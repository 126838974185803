import {Chart} from "react-google-charts";
import React, {useEffect, useState} from "react";
import Dropdown from 'react-dropdown';
import {withTranslation} from "react-i18next";
import CollateralService from "../../service/collateral-service";

function ChartCountyByStatus(props) {

  /* Const */
  const mockupData = [
    ["Status", "Quantity"],
    ["Active", 1],
    ["Locked", 0],
    ["Released", 0],
  ];
  const {t} = props;
  const itemsOption = ["Total", t('billing.simplified')];
  const options = {title: t('billing.total_by_status')};
  const [visibilityOption, setVisibilityOption] = useState(itemsOption[0])
  const [data, setData] = useState([])
  const [processedData, setProcessedData] = useState([])
  const isDataValid = data.length > 0 && data[0].length > 0;

  /* Load Data */
  useEffect(() => {
    const fetchData = async () => {
      const result = await CollateralService.countByStatus()
      setData(result.data)
    }
    fetchData()
  }, [])

  /* Apply Filter */
  useEffect(() => {
    const defaultData = [
      ["Status", "Quantity"],
      data[1],
      data[2],
      data[3],
    ];
    const simplifiedData = [
      ["Status", "Quantity"],
      data[1],
      data[3],
    ];
    if (visibilityOption === "Total") {
      setProcessedData(defaultData)
    } else {
      setProcessedData(simplifiedData)
    }
  }, [data, visibilityOption])

  /* Render Chart */
  return (
    <div className="group-components">
      <Dropdown className="form-select" options={itemsOption} onChange={(event) => setVisibilityOption(event.value)}
                value={visibilityOption} placeholder="Select an option"/>
      {isDataValid ? (
        <Chart
          className="chart"
          chartType="PieChart"
          data={processedData}
          options={options}
          width={"100%"}
          height={"400px"}
        />
      ) : (
        <div>
          <Chart
            className="chart"
            chartType="PieChart"
            data={mockupData}
            options={options}
            width={"100%"}
            height={"400px"}
          />
          <div className="alert">{t('billing.no_data_found')}</div>
        </div>
      )}
    </div>
  );
}

const ChartCountyByStatusTranslated = withTranslation()(ChartCountyByStatus)
export default ChartCountyByStatusTranslated

import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import flag_es from "../assets/images/ic_flag_es.png";
import flag_us from "../assets/images/ic_flag_us.png";
import flag_br from "../assets/images/ic_flag_br.png";
import routes from "../navigation/routes";
import Cookies from "js-cookie";
import AuthenticationService from "../service/authentication-service";
import StorageHelper from "../utils/helpers/storage-helper";
import * as Authentication from "../utils/helpers/authentication-helper";
import { useTranslation, withTranslation } from "react-i18next";

function Sidebar({ visible, show }) {
  const { t, i18n } = useTranslation()
  const isDev = process.env.REACT_APP_ENV === 'dev'
  const isAdmin = Authentication.isAdmin()
  const [languageOption, showOptions] = useState(false)
  const [loggedIn, isUserLoggedIn] = useState(Authentication.isAuthenticated())
  const isVisible = true
  const isVisibleSettings = true
  const isVisibleNotifications = true

  function logout() {
    isUserLoggedIn(false);
    localStorage.removeItem('loggedIn');
    Cookies.remove('oauth2Token');
    Cookies.remove('oauth2RefreshToken');
    Cookies.remove('logo');
    AuthenticationService.logout();
    StorageHelper.removeStateStorage();
    setTimeout(() => {
      window.location = '/';
    }, 100);
  }

  return (<>
    <div className="mobile-nav">
      <button
        className="mobile-nav-btn"
        onClick={() => show(!visible)}
      >
        <i className="bi bi-list"></i>
      </button>
    </div>
    <nav className={!visible ? 'navbar' : ''}>
      <button
        type="button"
        className="nav-btn"
        onClick={() => show(!visible)}
      >
        {!visible ? <i className="bi bi-chevron-right"></i> : <i className="bi bi-chevron-left"></i>}
      </button>
      <div>
        <NavLink
          className="logo"
          to="/"
        >
          {loggedIn ?
            <img
              src={AuthenticationService.getCookie('logo')}
              alt="logo"
            />
            :
            null
          }

        </NavLink>

        <div className="links nav-top">
          <NavLink to={routes.HOME} className="nav-link">
            <i className="bi bi-house"></i>
            <span>{t('aside.home')}</span>
          </NavLink>


          <NavLink to={routes.DASHBOARD} className="nav-link">
            <i className="bi bi-cash-coin"></i>
            <span>{t('aside.billing')}</span>
          </NavLink>

          <NavLink to={routes.KNOX_DEVICES} className="nav-link">
            <i className="bi bi-phone"></i>
            <span>{t('aside.knox_devices')}</span>
          </NavLink>

          {isVisibleSettings && (
            <NavLink to={routes.CUSTOMIZATION} className="nav-link">
              <i className="bi bi-gear"></i>
              <span>{t('aside.settings')}</span>
            </NavLink>)}

          <NavLink to={routes.PARTNERS} className="nav-link" style={{ display: isAdmin ? 'visible' : 'none' }} >
            <i className="bi bi-person"></i>
            <span>{t('aside.partners')}</span>
          </NavLink>

          {isVisibleNotifications && (
            <NavLink to={routes.NOTIFICATION} className="nav-link">
              <i className="bi bi-chat-left-dots"></i>
              <span>{t('aside.notification')}</span>
            </NavLink>
          )}

          {isVisible && (
            <a href="mailto:support@globetek.zendesk.com" className="nav-link">
              <i className="bi bi-mailbox"></i>
              <span>{t('aside.contact')}</span>
            </a>
          )}

          {languageOption === true ? <div className="nav-link" onClick={() => showOptions(false)}>
            <i className="bi bi-chevron-up"></i>
            <span>{t('aside.language')}</span>
          </div> : <div className="nav-link" onClick={() => showOptions(true)}>
            <i className="bi bi-chevron-down"></i>
            <span>{t('aside.language')}</span>
          </div>}
          <div className={languageOption ? 'language-option-visible' : 'language-option'}>
            <span className="language" onClick={() => i18n.changeLanguage('en')}>
              <img
                src={flag_us}
                alt={''}
              />
              EN
            </span>
            <span className="language" onClick={() => i18n.changeLanguage('es')}>
              <img
                src={flag_es}
                alt={''}
              />
              ES
            </span>
            <span className="language" onClick={() => i18n.changeLanguage('pt')}>
              <img
                src={flag_br}
                alt={''}
              />
              PT
            </span>
          </div>
        </div>
      </div>

      <div className="links">
        <NavLink to='#' className="nav-link" id="btn-logout" onClick={() => logout()}>
          <i className="bi bi-door-open"></i>
          <span>Logout</span>
        </NavLink>
      </div>
    </nav>
  </>);
}

const SidebarTranslated = withTranslation()(Sidebar);

export default SidebarTranslated;

import {Chart} from "react-google-charts";
import React, {useEffect, useState} from "react";
import Dropdown from "react-dropdown";
import CollateralService from "../../service/collateral-service";
import {withTranslation} from "react-i18next";

function ChartVisualizeDataByDate(props) {

  const {t} = props;
  const itemsOption = ["Day", "Week", "Month"];
  const options = {
    title: t('billing.total_by_period')
  };
  const mockupView = [
    ["Data", "Available", "Locked", "Released", "Active"],
    ["", 0, 0, 0, 0],
    ["", 0, 0, 0, 0],
    ["", 0, 0, 0, 0],
    ["", 0, 0, 0, 0]
  ];

  const [visibilityOption, setVisibilityOption] = useState(itemsOption[0])
  const [data, setData] = useState([])
  const [mockup] = useState(mockupView)

  const isDataValid = data.length > 0 && data[0].length > 0;

  useEffect(() => {
    const fetchData = async () => {
      // Todo: Crete helper to translate and handle request param
      const result = await CollateralService.visualizeDataByDate(visibilityOption)
      const array = result.data

      if (array.length !== 0) {
        const dataView = [
          ["Data", "Available", "Locked", "Released", "Active"],
          ...array.sort((a, b) => {
            return new Date(a[0]) - new Date(b[0]);
          })
        ];
        setData(dataView);
      }
    }
    fetchData()
  }, [visibilityOption])

  return (
    <div className="group-components">
      <Dropdown
        className="form-select"
        options={itemsOption}
        onChange={(event) => setVisibilityOption(event.value)}
        value={visibilityOption}
        placeholder="Select an option"
      />
      {isDataValid ? (
        <Chart
          className="chart"
          chartType="LineChart"
          data={data}
          options={options}
          width={"100%"}
          height={"400px"}
        />
      ) : (
        <div>
          <Chart
            className="chart"
            chartType="LineChart"
            data={mockup}
            options={options}
            width={"100%"}
            height={"400px"}
          />
          <div className="alert">{t('billing.no_data_found')}</div>
        </div>
      )}
    </div>
  );
}

const ChartVisualizeDataByDateTranslated = withTranslation()(ChartVisualizeDataByDate)
export default ChartVisualizeDataByDateTranslated

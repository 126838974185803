import React from 'react';
import LoadingComponent from 'components/ui/loading/loading-component';
import { withTranslation } from "react-i18next";
import routes from "../../navigation/routes";
import { Link } from "react-router-dom";
import KnoxDevicesTableTranslated from 'components/tables/knox-devices-table';
import * as Authentication from "../../utils/helpers/authentication-helper";

class AllKnoxDevices extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const { loading } = this.state;
    const { t } = this.props;

    const isAdmin = Authentication.isAdmin()

    if (loading) return <LoadingComponent />
    else {
      return <>
        <div className="demo-container">
          <header style={{ display: 'Flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p className="page-title">{t('knox_devices.title')}</p>
            <Link to={routes.IMEI} className="nav-link" style={{ display: isAdmin ? 'visible' : 'none' }}>
              <button className="short-button">{t('knox_devices.new_device')}</button>
            </Link>
          </header>
          <KnoxDevicesTableTranslated />
        </div>
      </>
    }
  }
}

const AllKnoxDevicesTranslated = withTranslation()(AllKnoxDevices);

export default AllKnoxDevicesTranslated;

import React from 'react';
import { withTranslation } from "react-i18next";

class AppKnoxGuardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      knoxGuard: props.value,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <label>Knox Guard</label>
        <input
          type="text"
          className="form-input"
          value={this.state.knoxGuard?.id}
          placeholder="ID"
          onChange={this.handleKnoxGuardIdChange}
        />
      </div>
    );
  }

  handleKnoxGuardIdChange = (event) => {

    let obj = this.state.knoxGuard
    obj.id = event.target.value

    this.setState({
      knoxGuard: obj
    });
    this.props.handle(obj)
  };
}

const AppKnoxGuardFormTranslated = withTranslation()(AppKnoxGuardForm)

export default AppKnoxGuardFormTranslated

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import CollateralService from '../../service/collateral-service';
import PartnerService from '../../service/partner-config-service';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import 'react-datepicker/dist/react-datepicker.css';

import { format, startOfMonth } from 'date-fns';
import ReportHelper from '../../utils/helpers/report-helper';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as Authentication from '../../utils/helpers/authentication-helper';
import ProductConfigHelper from '../../utils/helpers/product-config-helper';


function BillingTable(props) {
  const { t } = props;

  const currentDate = format(startOfMonth(new Date()), 'yyyy-MM-01');
  const [period, setPeriod] = useState(currentDate);
  const [dateObject, setDateObject] = useState(new Date());
  const [items, setItems] = useState([]);
  const [productCodes, setProductCodes] = useState([]);
  const isAdmin = Authentication.isAdmin()
  const [productCode, setProductCode] = useState(!isAdmin ? ProductConfigHelper.getProductCode() : '');

  const handleDateChange = (date) => {
    const formattedDate = format(startOfMonth(date), 'yyyy-MM-01');
    setPeriod(formattedDate);
    setDateObject(date);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await CollateralService.getBillingReport(period, productCode);
      setItems(result.data);
    };
    if(productCode !== '') {
      fetchData();
    }
  }, [period, productCode]);

  useEffect(() => {
    const loadPartners = async () => {
      try {
        const result = await PartnerService.findAllPartners(0, 500, 'DESC');
        if(result && result.data.content.length > 0) {
          const filteredProductCodes = result.data.content
            .filter(item => item.productCode !== 'demo')
            .map((item, index) => ({ value: index, label: item.productCode }));

          filteredProductCodes.sort((a, b) => a.label.localeCompare(b.label));
          setProductCodes(filteredProductCodes);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadPartners();
  }, []);

  const handlePartnerChange = (event) => {
    setProductCode(event.label);
  };

  const getProductKindLabel = (report) => {
    if (report) {
      const translationKey = productKindTranslations[report.productId];

      return translationKey ? translationKey : '-';
    }

    return '-';
  }

  const productKindTranslations = {
    0: t("billing.activation"),
    1: t("billing.maintenance"),
    5: t("billing.discount"),
    6: t("billing.total")
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    const tableColumn = [
      t("billing.product"),
      t("billing.quantity"),
      t("billing.total")
    ];

    const tableRows = items.map(item => [
      getProductKindLabel(item),
      ReportHelper.quantity(item),
      ReportHelper.total(item)
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save('billing-report.pdf');
  };

  /* Render Chart */
  return (
    // TODO: Translate
    <div className="group-components">
         <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
             <p className="silver label-same-size" style={{ margin: 0 }}>{t('billing.date_name')}</p>
             <DatePicker
               className="date-picker"
               selected={dateObject}
               onChange={handleDateChange}
               dateFormat="yyyy-MM"
               showMonthYearPicker
            />

            <div>
              <p className="silver label-same-size" style={{ margin: 0, display: isAdmin ? 'visible' : 'none' }}>{t('billing.partner')}</p>
            </div>

           {isAdmin ? (
             <div className="input-container" style={{width: '220px'}}>
             <Dropdown
               className="form-select-without-margin-horizontal"
               options={productCodes}
               onChange={handlePartnerChange}
               value={productCode}
               placeholder={t('billing.select')}
             /> </div>) :
             ''
            }
         </div>
      <table className="custom-table" style={{maxWidth: "100vw"}}>
        <thead className="table-header">
        <tr>
          <td>
            <div className="align-div">{t("billing.product")}</div>
          </td>
          <td>
            <div className="align-div">{t("billing.quantity")}</div>
          </td>
          <td>
            <div className="align-div">{t("billing.total")}</div>
          </td>
        </tr>
        </thead>
        <tbody className="custom-tbody">

        {items.map(item => {
          return <tr key={getProductKindLabel(item)}>
            <td>
              <div className="align-div">{getProductKindLabel(item)}</div>
            </td>
            <td>
              <div className="align-div">{ReportHelper.quantity(item)}</div>
            </td>
            <td>
              <div className="align-div">{ReportHelper.total(item)}</div>
            </td>
          </tr>
        })}
        </tbody>
      </table>
      <div className="center-all" style={{ marginTop: "50px", marginBottom: "50px" }}>
      <button onClick={generatePDF} className="short-button">{t('billing.download_billing')}</button>
      </div>
    </div>
  );
}

const BillingTableTranslated = withTranslation()(BillingTable)
export default BillingTableTranslated

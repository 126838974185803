import API from 'config/api';
import {now} from 'moment';

const CollateralService = {
  /**
   * @description Get the collateral by its executor item id.
   * @param {string} executorItemId The executor item id.
   * @returns {Promise<AxiosResponse<any>>} The collateral information.
   */
  findByExecutorItemId: (executorItemId) => {
    return API.get(`/collateral/${executorItemId}`);
  },

  activateCollateral: (executorItemId) => {
    return API.put(`/collateral/${executorItemId}/activate`);
  },

  updateCollateralDueDate: (executorItemId, newDueDate) => {
    // TODO: Format the date accordingly (YYYY-MM-DD HH:mm:ss)
    return API.put(
      `/collateral/${executorItemId}/update-due-date?newDueDate=${newDueDate}`
    );
  },

  updateProductItemId: (executorItemId, newProductItemId) => {
    return API.put(
      `/collateral/${executorItemId}/update-product-item-id?productItemId=${newProductItemId}`
    );
  },

  releaseCollateral: (executorItemId) => {
    return API.put(`/collateral/${executorItemId}/release`);
  },

  findCollaterals: (page, size, direction) => {
    return API.get(
      `/collateral?page=${page}&size=${size}&direction=${direction}`
    );
  },

  findAllPolicies: (page, size, direction) => {
    return API.get(
      `/collateral/policies?page=${page}&size=${size}&direction=${direction}`
    );
  },

  findCollateralByProductItemId: (productItemId, page, size, direction) => {
    return API.get(
      `/collateral/${productItemId}/product?page=${page}&size=${size}&direction=${direction}`
    );
  },

//API call userID
  findCollateralByUserId: (userId) => {
      return API.get(
        `/collateral/user/${userId}/userId`
      );
    },

  findCollateralById: (collateralId) => {
      return API.get(
        `/collateral/findById/${collateralId}`
      );
    },

  getReleaseCode: (executorItemId, dateTime) => {
    if (dateTime == null)
      return API.get(`/collateral/release-code/${executorItemId}`);
    else
      return API.get(
        `/collateral/release-code/${executorItemId}/?dateTime=${dateTime}`
      );
  },

  getTotalOfActivations: (startDate, endDate) => {
    if (startDate || endDate == null) {
      let dateObject = new Date().toLocaleDateString("fr-CA");
      // let month = dateObject.slice(3,5).replace("/", "");
      startDate = dateObject;
      endDate = dateObject;

      console.log("data: " + dateObject + "\nstartDate: " + startDate + "\nendDate: " + endDate)
    }

    return API.get(
      `/collateral/get-total-of-activations-by-product-code?startDate=${startDate}&endDate=${endDate}`
    );
  },

  getMean: (device) => {
    return API.get(
      `/collateral/${device}/mean`
    )
  },

  getLatestCurrency: () => {
    return API.get(
      `/collateral/currency`
    )
  },

  getQRCode: () => {
    return API.get(
      `/collateral/qrcode`
    )
  },

  updateLockType: (executorItemId, newLockType) => {
      return API.put(
        `/collateral/${executorItemId}/update-lock-type?lockType=${newLockType}`
      );
    },

  countByStatus: () => {
    return API.get(
      `/collateral/countByStatus`
    );
  },

  visualizeDataByDate: (date) => {
    return API.get(
      `/collateral/visualizeDataByDate/${date}`
    );
  },

  getBillingReport: (period, productCode) => {
    return API.get(
      `/collateral/getBillingReport/${productCode}/${period}`
    );
  },
  updateFields: (executorItemId, fields) => {
    return API.put(`/collateral/${executorItemId}/update-fields`, fields);
  },
  findAllFeesByProductCode: (productCode) => {
    return API.get(`/collateral/fee/${productCode}`);
  },
  getDiscountValueByPeriod: (productCode, period) => {
    return API.get(`/collateral/discount/${productCode}?period=${period}`);
  },
  updateBillingFields: (productCode, body) => {
    return API.post(`/collateral/update-billing/${productCode}`, body);
  },
};

export default CollateralService;


import React from 'react';
import history from 'navigation/history';
import * as Authentication from 'utils/helpers/authentication-helper';
import AuthenticationService from 'service/authentication-service';
import ValidationHelper from 'utils/helpers/validation-helper';
import StorageHelper from 'utils/helpers/storage-helper';
import routes from 'navigation/routes';
import Cookies from 'js-cookie';
import {getI18n, withTranslation} from "react-i18next";
import PartnerConfigService from "../../service/partner-config-service";
import {NavLink} from "react-router-dom";
import flag_es from "../../assets/images/ic_flag_es.png";
import flag_us from "../../assets/images/ic_flag_us.png";
import flag_br from "../../assets/images/ic_flag_br.png";
import LittleLoadingComponent from "../ui/loading/little-loading-component";
import ToastHelper from "../../utils/helpers/toast-helper";

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      username: '',
      password: '',
      formErrors: {
        email: '',
        password: '',
      },
      usernameValid: false,
      passwordValid: false,
      formValid: false,
      messageError: '',
      loggedIn: false,
      languageOption: false
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.authenticate = this.authenticate.bind(this);
  }

  componentDidMount() {
    if (Authentication.isAuthenticated()) {
      history.push(routes.HOME);
    }
  }

  handleUserInput(e) {
    const {name} = e.target;
    const {value} = e.target;

    this.setState({...this.state, [name]: value}, () => {
      this.validateField(name, value);
    });
  }

  validateForm() {
    this.setState({
      ...this.state,
      formValid: this.state.usernameValid && this.state.passwordValid,
    });
  }

  validateField(fieldName, value) {
    const {t} = this.props;
    const fieldValidationErrors = this.state.formErrors;
    let {usernameValid} = this.state;
    let {passwordValid} = this.state;

    switch (fieldName) {
      case 'username':
        usernameValid = ValidationHelper.checkValidity(value, {
          email: true,
        });
        usernameValid = true
        fieldValidationErrors.username = usernameValid ? '' : t('login.email_invalid');
        break;
      case 'password':
        passwordValid = value.length >= 8;
        fieldValidationErrors.password = passwordValid
          ? ''
          : t('login.password_invalid');
        break;
      default:
        break;
    }
    this.setState(
      {
        ...this.state,
        passwordValid,
        usernameValid,
        formErrors: fieldValidationErrors,
      },
      this.validateForm
    );
  }

  authenticate(event) {
    event.preventDefault()
    let I18n = getI18n()

    // Validates if username and password are not empty.
    if (!this.state.username || !this.state.password) {
      ToastHelper.showWarning(I18n.t('login.user_password_invalid'));
    }

    const {t, i18n} = this.props;
    this.setState({...this.state, loading: true, messageError: ''});

    AuthenticationService.authenticate(
      this.state.username,
      this.state.password
    ).then(
      (response) => {

        if(Authentication.userIsAdmin(this.state.username)) {
          Cookies.set('isAdmin', true);
        } else {
          Cookies.remove('isAdmin');
        }

        this.setState({...this.state, loading: false});
        Cookies.set('oauth2Token', response.data.tokenValue);
        localStorage.setItem('loggedIn', 'true');
        StorageHelper.removeStateStorage();
        this.getPartnerConfig().then(r => r ? window.location = routes.ROOT : null);
      },
      (error) => {
        this.setState({...this.state, loading: false});
        console.error(error)
        ToastHelper.showWarning(I18n.t('login.user_password_invalid'));
      }
    );
  }

  getPartnerConfig = async () => {
    try {
      const response = await PartnerConfigService.findPartnerConfig();
      const partnerConfiguration = response.data;
      // Populate cookies with partner configuration.
      Cookies.set('logo', partnerConfiguration?.customInterface?.logo );
      Cookies.set('productCode', partnerConfiguration?.productCode );
      return true;
    } catch (error) {
      // TODO: Handle error.
      console.error(error);
    }
  };

  render() {
    const {t, i18n} = this.props;
    return (
      <div className="login-container">
        {/* SELECT LANGUAGE */}
        <div className="language-login black">
          {this.state.languageOption ? <div className="nav-link"
                                            onClick={
                                              () => {
                                                this.setState({
                                                  languageOption: false
                                                })
                                              }}
          >
            <i className="bi bi-chevron-up"></i>
            <span>{t('aside.language')}</span>
          </div> : <div className="nav-link"
                        onClick={
                          () => {
                            this.setState({
                              languageOption: true
                            })
                          }}
          >
            <i className="bi bi-chevron-down"></i>
            <span>{t('aside.language')}</span>
          </div>}


          <div className={this.state.languageOption ? 'language-option-visible' : 'language-option'}>
          <span className="language" onClick={() => i18n.changeLanguage('en')}>
                <img
                  src={flag_us}
                  alt={''}
                />
                EN
              </span>
            <span className="language" onClick={() => i18n.changeLanguage('es')}>
                <img
                  src={flag_es}
                  alt={''}
                />
                ES
              </span>
            <span className="language" onClick={() => i18n.changeLanguage('pt')}>
                <img
                  src={flag_br}
                  alt={''}
                />
                PT
              </span>
          </div>
        </div>

        {/* LOGIN FORM */}
        <form id="loginForm" className="form glass-effect" onSubmit={this.authenticate}>
          <div className="background-image-profile">
            <h1><b>GLOBE</b>TEK</h1>
          </div>
          <label><b>{t('login.user')}</b></label>
          <input type="text"
                 name="username"
                 className="form-input remove-bg"
                 placeholder={t('login.user_form')}
                 value={this.state.username}
                 onChange={this.handleUserInput}
                 required
          />

          <label><b>{t('login.password')}</b></label>
          <input type="password"
                 name="password"
                 className="form-input remove-bg"
                 placeholder={t('login.password_form')}
                 value={this.state.password}
                 onChange={this.handleUserInput}
                 required
          />
          <br/>
          <NavLink to={routes.REQUEST_CHANGE_PASSWORD}>
            {t('login.still_no_password_forgot')}
          </NavLink>
          <br/><br/>
          <button className="form-button button-login" type="submit">
            {t('login.login')}
            {this.state.loading && (
              <LittleLoadingComponent/>
              )}{' '}
            </button>
        </form>
      </div>
    );
  }
}

const LoginComponentTranslated = withTranslation()(LoginComponent)

export default LoginComponentTranslated

import React from 'react';
import {withTranslation} from 'react-i18next';
import LoadingComponent from 'components/ui/loading/loading-component';
import ChartCountyByStatusTranslated from "../../components/charts/ChartCountyByStatus";
import ChartVisualizeDataByDate from "../../components/charts/ChartVisualizeDataByDate";
import BillingTableTranslated from "../../components/tables/billing-table";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as Authentication from '../../utils/helpers/authentication-helper';

class PartnerDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAdmin: Authentication.isAdmin()
    };
  }

  render() {
    const {loading} = this.state;
    const {t, i18n} = this.props;

    if (loading) return <LoadingComponent/>;
    else {
      return (
        <>
          <section className="dashboard">
            <p className="page-title">{t('billing.billing')}</p>
            <section className="charts">
              <section style={{ width: '100%' }}>
                <p className="page-title">{t('billing.monthly_report_billing')}</p>
                <BillingTableTranslated />
              </section>
              <section style={{ width: '100%' }}>
                <p className="page-title">{t('aside.dashboard')}</p>
                <ChartCountyByStatusTranslated />
                <br></br>
                <ChartVisualizeDataByDate />
              </section>
            </section>
          </section>
        </>
      );
    }
  }
}

const PartnerDashboardTranslated = withTranslation()(PartnerDashboard);

export default PartnerDashboardTranslated;
